import Spline from "@splinetool/react-spline";

export default function Chatbot() {
  return (
    <div id="splineContainer">
      <div id="spline">
      <Spline scene="https://prod.spline.design/w92bkOvtDXCiEQkj/scene.splinecode" />
      </div>
    </div>
  );
}
