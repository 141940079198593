import React from "react";
import ReactDOM from "react-dom/client";
import NavBar from "./navbar";
import Chatbot from "./chatbot";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <NavBar />
    <Chatbot />
  </React.StrictMode>
);
