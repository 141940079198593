import React, { useState, useEffect } from "react";
import {
  HashRouter as Router,
  Route,
  Routes,
} from "react-router-dom";

const NavBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const bodyStyle = {
    padding: "none",
    margin: "none",
    width: "100%",
  };

  const navBarStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#0D222E",
    padding: "1% 0",
    width: "100%",
    position: "relative",
    zIndex: 1001,
  };

  const linkStyle = {
    color: "white",
    textDecoration: "none",
    fontSize: "16px",
    fontFamily: "Monsteratt, sans-serif !important",
    padding: "10px",
  };

  const menuStyle = {
    display: isMenuOpen ? "block" : "none",
    position: "absolute",
    top: "100%",
    left: 0,
    right: 0,
    backgroundColor: "#0D222E",
    textAlign: "center",
  };

  const hamburgerStyle = {
    display: isMobileView ? "block" : "none",
    fontSize: "30px",
    color: "white",
    cursor: "pointer",
    marginLeft: "auto",
    marginRight: "auto",
  };

  const navLinksContainerStyle = {
    display: isMobileView ? "none" : "flex",
    justifyContent: "space-around",
    width: "100%",
    fontFamily: "Monsteratt, sans-serif !important",
  };

  const navLinksMobileStyle = {
    display: "flex",
    flexDirection: "column",
  };

  return (
    <Router>
      <div style={bodyStyle}>
        <div style={navBarStyle} className="navbarStyle">
          <div
            style={hamburgerStyle}
            onClick={toggleMenu}
            className="hamburger"
          >
            &#9776;
          </div>
          <div style={navLinksContainerStyle}>
            <a
              href="https://webwizardsg.com/"
              style={linkStyle}
              className="navlinks"
            >
              About EvaluatorAI
            </a>
            <a
              href="https://buy.stripe.com/14k3f1dVX73V2o89AH"
              style={linkStyle}
              className="navlinks"
            >
              Subscribe to EvaluatorAI
            </a>
            <a
              href="https://webwizardsg.com/"
              style={linkStyle}
              className="navlinks"
            >
              Visit Our Website
            </a>
            <a
              href="https://webwizardsg.site/contact.html"
              style={linkStyle}
              className="navlinks"
            >
              Buy our domain or contact us
            </a>
          </div>
          {isMenuOpen && (
            <div style={{ ...menuStyle, ...navLinksMobileStyle }}>
              <a
                href="https://buy.stripe.com/14k3f1dVX73V2o89AH"
                style={linkStyle}
                className="navlinks"
                onClick={toggleMenu}
              >
                Subscribe to EvaluatorAI
              </a>
              <a
                href="https://webwizardsg.com/"
                style={linkStyle}
                className="navlinks"
                onClick={toggleMenu}
              >
                Visit our Website
              </a>

              <a
                href="https://webwizardsg.com/"
                style={linkStyle}
                className="navlinks"
              >
                About EvaluatorAI
              </a>
              <a
                href="https://webwizardsg.site/contact.html"
                style={linkStyle}
                className="navlinks"
                onClick={toggleMenu}
              >
                Buy our domain or contact us
              </a>
            </div>
          )}
        </div>
        {/* <Carmart /> */}
        <Routes>
          {/* <Route path="/ClaudeSonnet" element={<ClaudeSonnet />} /> */}
          {/*  <Route path="/contact" element={<ParentContact />} />
    <Route path="*" element={<Navigate replace to="/" />} /> */}
        </Routes>
      </div>
    </Router>
  );
};

export default NavBar;
